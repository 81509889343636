import React from 'react';
import HeroBlock from '../../blocks/Hero';
import ParallaxPage from '../../layout/components/ParallaxPage';
import PurpleSection from '../../sections/Purple';
import ColorHeaderBabyPink from '../../sections/ColorHeaderBabyPink';
import ColorHeaderBlue from '../../sections/ColorHeaderBlue';
import ColorHeaderGreen from '../../sections/ColorHeaderGreen';
import ColorHeaderHotPink from '../../sections/ColorHeaderHotPink';
import TallBlackAlternateSection from '../../sections/TallBlackAlternate';
import { Section, Ad, Image } from '../../components';
import theme from '../../styles/theme';

class StagePage extends ParallaxPage {
  constructor(props) {
    super(props);
  }

  renderChildren() {
    const { content } = this.props;

    let ColorHeader;
    let dividerColor;
    let textColor;

    switch (content.headerColor) {
      case 'hotPink':
        ColorHeader = ColorHeaderHotPink;
        dividerColor = theme.colors.hotPink;
        textColor = theme.colors.white;
        break;
      case 'babyPink':
        ColorHeader = ColorHeaderBabyPink;
        dividerColor = theme.colors.babyPink;
        textColor = theme.colors.white;
        break;
      case 'green':
        ColorHeader = ColorHeaderGreen;
        dividerColor = theme.colors.green;
        textColor = theme.colors.black;
        break;
      case 'blue':
        ColorHeader = ColorHeaderBlue;
        dividerColor = theme.colors.blue;
        textColor = theme.colors.black;
        break;
      default:
        ColorHeader = ColorHeaderBlue;
        dividerColor = theme.colors.blue;
        textColor = theme.colors.black;
        break;
    }

    return (
      <>
        {content && (
          <>
            {content.title && (
              <ColorHeader isFirstSection={true} pageHasAnnouncement={this.pageHasAnnouncement()}>
                <HeroBlock
                  title={content.title}
                  leadingParagraph={content.leadingParagraph}
                  returnURL={content.wordpress_parent && content.wordpress_parent.permalink}
                  returnLabel={content.wordpress_parent && content.wordpress_parent.title}
                  textColor={textColor}
                />
              </ColorHeader>
            )}
            <TallBlackAlternateSection
              isFirstSection={!content.title}
              pageHasAnnouncement={this.pageHasAnnouncement()}
              topDividerColor={dividerColor}
              noFooterBlocks={!content.footerBlocks || content.footerBlocks.length === 0}
            >
              <Section.Content>
                <Section.ContentInner hasSidebar={(content.adImage || content.adCode)}>
                  {content.contentBlocks &&
                    content.contentBlocks.map((contentBlock, index) => {
                      return this.renderBlock(contentBlock, index);
                    })}
                </Section.ContentInner>
                {(content.adImage || content.adCode) && (
                  <Section.Sidebar>
                    {content.adCode && <Ad {...content.adCode} />}
                    
                    {content.adImage && (
                      <a href={content.adImage.link.url} target="_blank">
                        <Image {...content.adImage.image} />
                      </a>
                    )}
                  </Section.Sidebar>
                )}
              </Section.Content>
              {content.footerBlocks &&
                content.footerBlocks.map((footerBlock, index) => {
                  if (index < content.footerBlocks.length - 1) {
                    return this.renderBlock(footerBlock, index);
                  }
                  return;
                })}
            </TallBlackAlternateSection>
          </>
        )}
        {content && content.footerBlocks && content.footerBlocks.length > 0 && (
          <PurpleSection>{this.renderBlock(content.footerBlocks[content.footerBlocks.length - 1])}</PurpleSection>
        )}
      </>
    );
  }
}

export default StagePage;
